<template>
  <div class="brankas-success">
    <app-brankas-success-page />
  </div>
</template>

<script>
import BrankasSuccessPageComponent from "@/components/BrankasSuccessPage/BrankasSuccessPageComponent.vue";

export default {
  name: "BrankasSuccessPageView",
  components: {
    "app-brankas-success-page": BrankasSuccessPageComponent,
  },
};
</script>
