<template>
  <div class="brankas-error">
    <app-brankas-error-page />
  </div>
</template>

<script>
import BrankasErrorPageComponent from "@/components/BrankasErrorPage/BrankasErrorPageComponent.vue";

export default {
  name: "BrankasErrorPageView",
  components: {
    "app-brankas-error-page": BrankasErrorPageComponent,
  },
};
</script>
